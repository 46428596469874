@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


body {
  margin: 0;
  font-family: "Roboto", sans-serif !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-w-10xl {
  max-width: 104rem
}

.max-w-11xl {
  max-width: 112rem
}

.css-tylwuq .remirror-theme .ProseMirror {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  overflow: hidden !important;
  border-color: rgb(209 213 219) !important;
  border-width: 1px !important;
  box-shadow: none !important;
  border-radius: 0.375rem !important;
}

.css-tylwuq .remirror-theme .ProseMirror:focus {
  outline-color: #4f46e5 !important;
  outline-offset: 0px !important;
  outline-width: 2px !important;
  outline-style: solid !important;
}

.remirror-editor ul {
  list-style-type: disc !important;
}

.remirror-editor ol {
  list-style-type: decimal !important;
}    

.css-6pu3k5-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: #4F46E5 !important;
}